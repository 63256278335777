import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend,
  localize,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted';
import Rollbar from 'rollbar';

// install validation rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// add vee validate rules
extend("katakana_only", {
  validate(value) {
    return value.match(/^[ァ-ヶー]+$/)
  },
  message: '{_field_} はカタカナのみで入力して下さい。'
});

localize("ja", ja);
configure({
  classes: {
    invalid: 'is-invalid'
  }
})

// Install components globally
// TODO : globalに読んでいるはずがagreeTermItem.vueで読み込めていないので調査する。
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// for VModal
Vue.use(VModal);

// for notification
const toastedOptions = {
  theme: 'outline',
  position: 'top-center',
  duration: 3000,
  action: {
    text: '×',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
}
Vue.use(Toasted, toastedOptions);

// for rollbar
Vue.prototype.$rollbar = new Rollbar({
  accessToken: '9b888c7ef0e84db1919e735e25f19d68',
  captureUncaught: true,
  captureUnhandledRejections: true,
});

Vue.config.errorHandler = (err, vm, info) => { // eslint-disable-line
  vm.$rollbar.error(err);
  throw err; // rethrow
};

const mount = (components: Record<string, unknown>): void => {
  document.addEventListener("DOMContentLoaded", () => {
    const templates = document.querySelectorAll("[data-vue]");

    for (const el of templates) {
      const data = {
        render: (createElement) => {
          const context = {
            props: { ...el.dataset },
          };
          return createElement(components[el.dataset.vue], context);
        },
      };
      const app = new Vue(data);
      app.$mount(el);
    }
  });
};

export { mount };
